import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    token: '',
  },
  getters: {
  },
  mutations: {
    getToken(state, value) {
      state.token = value;
    },
    loginout(state) {
      state.token = '';
    }
  },
  actions: {
    GETTOKEN({ commit }, value) {
      commit('getToken', value);
    },
    LOGINOUT({ commit }) {
      commit('loginout');
    }
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      storage: localStorage,
      key: 'token',
      reducer(state){
        return state.token;
      }
    }),
  ]
})
