import Vue from 'vue'
import { Button,Tab, Tabs,Tag, Uploader,Step, Steps, Form,Field ,Image as VanImage,Icon   } from 'vant';



Vue.use(Button);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Tag );
Vue.use(Uploader);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Form);
Vue.use(Field);
Vue.use(VanImage);
Vue.use(Icon );