import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index';
import { Dialog, Toast, Notify } from 'vant';

import { getUid } from '@/api/login.js';

// 解决 重复跳转路由报错
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/LoginView'),
    meta: {
      title: '登录'
    }
  },
  // {
  //   path: '/test',
  //   name: 'test',
  //   component: () => import('@/views/test/index.vue'),
  //   meta: {
  //     title: '测试'
  //   }
  // },
  {
    path: '/notice',
    name: 'notice',
    component: () => import('@/views/work_order/NoticeListView.vue'),
    meta: {
      title: '通知列表',
    }
  },
  {
    path: '/assign',
    name: 'assign',
    component: () => import('@/views/work_order/WorkOrderAssignView.vue'),
    meta: {
      title: '工单指派受理',
    }
  },
  {
    path: '/milestone',
    name: 'milestone',
    component: () => import('@/views/work_order/WorkOrderMilestoneView.vue'),
    meta: {
      title: '工单里程碑审核',
    }
  },
  {
    path: '/delayed-acceptance',
    name: 'delayed',
    component: () => import('@/views/work_order/DelayedAcceptanceView.vue'),
    meta: {
      title: '待办延期受理',
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) { // 判断是否有标题
    document.title = to.meta.title;
    next();
  }
  // console.log(store.dispatch);
  // if (!store.state.token) {
  //   // store.dispatch()
  //   const data = await getUid(to.query.uid);
  //   store.state.token = data.data.token;
  //   window.localStorage.setItem('token', data.data.token);
  //   next();
  // }else{
  //   next();
  // }
  // console.log(window.localStorage.getItem(''));

  // if (to.meta.requireAuth) {  // 需要权限
  //   //判断当前是否拥有权限
  //   if (store.state.info.token) {
  //     next();
  //   } else{
  //     let data = await getUid(to.query.uid);
  //     console.log('token为空',data.data.token);
  //     // console.log(JSON.parse(window.localStorage.getItem('vuex')));

  //     window.localStorage.setItem('vuex', {info: data.data.token});
  //     store.state.info.token = data.data.token;
  //     next();
  //   }
  // } else {  //不需要权限，直接访问
  //   next();
  // }
});

export default router
