import 'babel-polyfill';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

import '../src/utils/vant'
import 'lib-flexible'

import uploader from 'vue-simple-uploader'
import { Dialog,Toast,Notify   } from 'vant';

import Vconsole from 'vconsole'

// 所有环境均使用
const vConsole = new Vconsole()
Vue.use(vConsole)



Vue.prototype.$axios = axios;
Vue.prototype.$confirm = Dialog.confirm;
Vue.prototype.$toast = Toast;
Vue.prototype.$notify = Notify;
Vue.config.productionTip = false

Vue.use(uploader)
// 全局注册

Vue.use(Dialog);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
