
import http from '@/utils/request'
// 获取验证码
export const getCode = () => http.get("/captchaImage");

// 登录
export const postLogin = (data) => http.post("/login",data);

// 登出
export const postLogOut = () => http.post("/logout");

// uid获取token
export const getUid = (uid) => http.get(`/mssoLogin/${uid}`);
