// 封装 通用的axios的实例
import axios from "axios";
import store from '@/store/index';
import { Notify } from 'vant';

import { getUid } from '@/api/login.js';


const apis = {
  production: 'https://gdtz.web.polydt.com', // 线上地址 (生产环境)
  development: 'http://192.168.1.57:8080', // 本地 (开发环境)
}

// 创建了一个 axios的实例
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 30000,  // 请求超时
  withCredentials: false,  // 涉及跨域时 是否携带cookie
});

// 拦截器 
// 1. 请求拦截器
instance.interceptors.request.use((config) => {
  config.headers.Authorization = '';
  console.log('请求被拦截');
  // 每一次请求前 检查本地存储中是否有token 如果有放在请求头部中的 Authorization字段
  let token = '';
  try {
    token = store.state.token;
  } catch (e) {
  }
  if (token) {
    config.headers.Authorization = token;
  } else {
    // token = store.state.token;
    // config.headers.Authorization = store.state.token;
  }
  // 一定要写return
  // config.headers["Authorization"] = window.localStorage.getItem('token');

  console.log( config.headers,'斤斤计较token');
  return config;
}, (error) => {
  return Promise.reject(error);  // 直接生成一个状态为rejected的promise实例
});

// 2. 响应拦截器
instance.interceptors.response.use((response) => {
  const { code, msg } = response.data;
  if(code == 401){
    // Notify({ type: 'warning', message: '没有限权访问!'});
    // if(msg){
    //   window.location = '/login'
    // }else{
    // }
    // window.location.reload();
    // window.location = '/login';
  } 
  return response;
}, (error) => {
  // const { data, status } = error.response;

  // if (data.msg) {
  //   if (typeof data.msg === 'object') {
  //     msgText = Object.values(data.msg).join(' ')
  //   } else if (typeof data.msg === 'string') {
  //     msgText = data.msg
  //   }
  // }


  return Promise.reject(error);
})




export default instance